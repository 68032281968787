import { inject, Injectable } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ApiService } from '@shared/services/api.service';
import { ToastService } from '@shared/services/toast.service';
import { map } from 'rxjs';
import { DrillFormAPIData } from './drill.types';

type StepRequirementEvaluation = 'SingleShot' | 'Min' | 'Max' | 'Average';
type StepRequirementStat = 'TotalDistance'; // TODO ADD MORE

type DrillResponse = {
  items: Drill[];
  total: number;
};

export type Drill = {
  id: string;
  title: string;
  description: string | null;
  game_activity_blob: {
    type: string;
    steps: DrillStep[];
  };
};

export type DrillStep = {
  title: string | null;
  requirements: DrillStepRequirement[];
  completion_criteria: {
    shots_hit: number | null;
    shots_passed: number | null;
    consecutive_shots_passed: number | null;
  };
};

export type DrillStepRequirement = {
  collection_evaluation_type: StepRequirementEvaluation;
  shot_stat: StepRequirementStat;
  min_value: number | null;
  max_value: number | null;
};

@Injectable({
  providedIn: 'root',
})
export class DrillService {
  #api = inject(ApiService);
  #router = inject(Router);
  #toast = inject(ToastService);

  drills = rxResource({
    loader: () => this.#api.get<DrillResponse>(`activity-templates`).pipe(map(response => response.items)),
  });

  refreshDrillsList(): void {
    this.drills.reload();
  }

  deleteDrill(drillId: Drill['id']) {
    return this.#api.delete(`activity-templates/${drillId}`).subscribe({
      next: () => {
        this.refreshDrillsList();
      },
      error: () => {
        this.#toast.negative('error.generic.title', 'error.generic.description');
      },
    });
  }

  sendDrillToAPI(data: DrillFormAPIData) {
    return this.#api.post(`activity-templates`, data).subscribe({
      next: () => {
        this.refreshDrillsList();
        this.#router.navigate(['/drills/overview']);
      },
      error: () => {
        this.#toast.negative('error.generic.title', 'error.generic.description');
      },
    });
  }
}
